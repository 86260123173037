import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
	Box,
	Typography,
	Button,
	Paper,
	Container,
	Stack,
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';

const withTranslation = (WrappedComponent) => {
	function WithTranslation(props) {
		const { t } = useTranslation();
		return <WrappedComponent t={t} {...props} />;
	}
	return WithTranslation;
};

class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hasError: false,
			error: null,
		};
	}

	static getDerivedStateFromError(error) {
		return { hasError: true, error };
	}

	componentDidCatch(error, errorInfo) {
		console.error('Error caught by ErrorBoundary:', error, errorInfo);

		const { extraData } = this.props;
		Sentry.captureException(error, {
			extra: {
				componentStack: errorInfo.componentStack,
				...extraData,
			},
		});
	}

	handleRefresh = () => {
		this.setState({ hasError: false });
		window.location.href = '/';
	};

	render() {
		const { t, children } = this.props;
		const { hasError, error } = this.state;

		if (hasError) {
			return (
				<Box
					sx={{
						position: 'fixed',
						top: 0,
						left: 0,
						width: '100%',
						height: '100%',
						zIndex: 9999,
						bgcolor: 'background.paper',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						overflow: 'auto',
						p: 2,
					}}
				>
					<Container maxWidth="md" sx={{ py: 4 }}>
						<Paper
							elevation={3}
							sx={{
								p: 4,
								textAlign: 'center',
								borderRadius: 2,
								border: '1px solid #f0f0f0',
							}}
						>
							<Box sx={{ mb: 3 }}>
								<ErrorOutlineIcon color="error" sx={{ fontSize: 72 }} />
							</Box>

							<Typography variant="h4" gutterBottom color="error">
								{t('errorBoundary.title', 'Something went wrong')}
							</Typography>

							<Typography variant="body1" color="textSecondary" sx={{ mb: 3 }}>
								{t(
									'errorBoundary.message',
									'Sorry, an error occurred while the application was running.',
								)}
							</Typography>

							<Paper
								variant="outlined"
								sx={{
									p: 2,
									mb: 4,
									textAlign: 'left',
									overflow: 'auto',
									maxHeight: '200px',
								}}
							>
								<Typography
									variant="body2"
									component="pre"
									sx={{ fontFamily: 'monospace' }}
								>
									{error ? error.toString() : 'Unknown error'}
								</Typography>
							</Paper>

							<Typography variant="h6" gutterBottom>
								{t(
									'errorBoundary.possibleReasons',
									'Possible causes of the error:',
								)}
							</Typography>

							<Stack spacing={1} sx={{ mb: 4, textAlign: 'left', pl: 2 }}>
								<Typography variant="body2">
									•{' '}
									{t(
										'errorBoundary.reason.browser',
										'You are using an outdated browser version.',
									)}
								</Typography>
								<Typography variant="body2">
									•{' '}
									{t(
										'errorBoundary.reason.extensions',
										'The problem may be caused by installed browser extensions.',
									)}
								</Typography>
								<Typography variant="body2">
									•{' '}
									{t(
										'errorBoundary.reason.cache',
										'Outdated data might remain in your browser memory.',
									)}
								</Typography>
								<Typography variant="body2">
									•{' '}
									{t(
										'errorBoundary.reason.connection',
										'Internet connection problems.',
									)}
								</Typography>
							</Stack>

							<Stack direction="row" spacing={2} justifyContent="center">
								<Button
									variant="contained"
									color="primary"
									startIcon={<RefreshIcon />}
									onClick={this.handleRefresh}
								>
									{t('errorBoundary.refresh', 'Return to home page')}
								</Button>
							</Stack>
						</Paper>
					</Container>
				</Box>
			);
		}

		return children || null;
	}
}

ErrorBoundary.defaultProps = {
	children: null,
	extraData: {},
};

ErrorBoundary.propTypes = {
	children: PropTypes.node,
	t: PropTypes.func.isRequired,
	extraData: PropTypes.shape({}),
};

export default withTranslation(ErrorBoundary);
