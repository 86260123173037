import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { RouterProvider } from 'react-router-dom';
import { Snackbar } from './components/@extended/Snackbar';
import SendPulseChat from './components/common/SendPulseChat';
import ErrorBoundary from './components/common/ErrorBoundary';
import rootStore from './stores/rootStore';
import { RootStoreProvider } from './stores/context';
import { routes } from './navigation';
import { AxiosInterceptor } from './api';
import ThemeCustomization from './styles/themes';
import './styles/index.scss';
import './i18n';

function App() {
	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const token = urlParams.get('token');
		if (token) rootStore.authStore.loginWithMagicLink(token);
		rootStore.authStore.restore();
	}, [rootStore.authStore]);

	useEffect(() => {
		rootStore.appStore.getThemeMode();
	}, []);

	useEffect(() => {
		const notifications = localStorage.getItem('teleshopNotifications');

		const parsedNotifications = notifications
			? JSON.parse(notifications)
			: null;

		if (Array.isArray(parsedNotifications)) {
			localStorage.setItem('teleshopNotifications', JSON.stringify({}));
		}
	}, []);

	useEffect(() => {
		const userId = rootStore.authStore.user?.id;
		if (userId) {
			const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
			const currentTime = new Date();
			const allNotifications =
				JSON.parse(localStorage.getItem('teleshopNotifications')) || {};
			const userNotifications =
				!Array.isArray(allNotifications) && allNotifications[userId]
					? allNotifications[userId]?.filter((notification) => {
							const notificationDate = new Date(notification.date);
							return (
								!notification.read &&
								currentTime - notificationDate <= oneDayInMilliseconds
							);
					  })
					: [];

			rootStore.notificationsStore.setNotifications(userNotifications ?? []);
		}
	}, [rootStore.authStore.user?.id]);

	return (
		<RootStoreProvider value={rootStore}>
			<AxiosInterceptor>
				<ThemeCustomization>
					<Snackbar />
					<SendPulseChat />
					<ErrorBoundary>
						<RouterProvider router={routes} />
					</ErrorBoundary>
				</ThemeCustomization>
			</AxiosInterceptor>
		</RootStoreProvider>
	);
}

export default observer(App);
