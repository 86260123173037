import { types } from 'mobx-state-tree';
import { Product } from '../productsStore/model';
import { OrderBy } from '../sharedModels';

const ShippingAddress = types.model('ShippingAddress', {
	id: types.maybeNull(types.identifierNumber),
	type: types.optional(types.string, ''),
	city: types.maybeNull(types.string),
	npDepartment: types.maybeNull(types.string),
	shopLocation: types.maybeNull(types.string),
	destination: types.maybeNull(types.string),
	street: types.optional(types.string, ''),
	building: types.optional(types.string, ''),
	entrance: types.optional(types.string, ''),
	apartment: types.optional(types.string, ''),
});

const OrderItem = types.model('OrderItem', {
	id: types.maybeNull(types.identifierNumber),
	quantity: types.maybeNull(types.number),
	subTotalAmount: types.maybeNull(types.number),
	title: types.maybeNull(types.string),
	product: types.maybeNull(Product),
});

const Order = types.model('Order', {
	id: types.maybeNull(types.identifierNumber),
	isPayed: types.maybeNull(types.boolean),
	isQuantityUpdated: types.maybeNull(types.boolean),
	skipConfirmationCall: types.maybeNull(types.boolean),
	fullName: types.optional(types.string, ''),
	phoneNumber: types.optional(types.string, ''),
	email: types.optional(types.string, ''),
	comment: types.maybeNull(types.string),
	ttn: types.maybeNull(types.string),
	totalAmount: types.optional(types.number, 0),
	paymentType: types.optional(types.string, ''),
	updatedAt: types.maybeNull(types.string),
	createdAt: types.maybeNull(types.string),
	status: types.optional(types.string, ''),
	orderNumber: types.optional(types.number, 0),
	shippingAddress: types.maybeNull(ShippingAddress),
	orderItems: types.optional(types.array(OrderItem), []),
});

const OrderFilters = types.model('OrderFilters', {
	paymentType: types.maybeNull(types.string),
	deliveryType: types.maybeNull(types.string),
	isPayed: types.maybeNull(types.boolean),
	status: types.maybeNull(types.string),
	customerId: types.maybeNull(types.number),
});

const OrderSearchQuery = types.model('OrderSearchQuery', {
	search: types.optional(types.string, ''),
	page: types.optional(types.number, 1),
	limit: types.optional(types.number, 10),
	order: types.optional(OrderBy, {}),
	filters: types.optional(OrderFilters, {}),
	columnVisibility: types.optional(types.frozen(), {}),
	columnOrder: types.optional(types.array(types.string), []),
	columnDimensions: types.optional(types.frozen(), {}),
	pinnedColumns: types.optional(types.frozen(), {}),
});

export { Order, OrderSearchQuery };
